//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  data() {
    return {
      ds: null, //开始时间
      de: null, //结束时间
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 15,
        totalNum: 0,
      }, //分页
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [], //选择的成员
      dateType: 1, //时间查询类型
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  mounted() {
    this.getTeams();
  },
  methods: {
    /**
     * 获取列表数据
     */
    handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      this.pageData.pageIndex = 1;
      this.tableData = [];
      this.getSalary();
    },
    /**
     * 获取报表
     */
    getSalary() {
      this.loading = true;
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      const data = {
        sdate: this.ds,
        edate: this.de,
        usIds: this.selMem.map((m) => m.UsId),
        pageIndex: this.pageData.pageIndex,
        pageSize: this.pageData.pageSize,
        teamId: this.teamValue,
        datetype: this.dateType,
      };
      this.$http
        .post("/User/Work/GetMemberPayrollReport.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.tableData = resp.data.Data;
            if (this.tableData && this.tableData.length) {
              this.tableData.forEach((m, index) => {
                m.id = index + 1;
                m.RepairWork = m.SumRepairWork;
                m.Salary = m.SumSalary;
                if (m.MemberWageTypes && m.MemberWageTypes.length) {
                  m.MemberWageTypes.forEach((s, sind) => {
                    s.id = `${index + 1}-${sind}`;
                  });
                }
              });
            }
            this.pageData.totalNum = resp.data.totalCount;
          }
        })
        .finally(() => (this.loading = false));
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    pickDate() {
      let _this = this;
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 获取团队
     */
    getTeams() {
      this.$http
        .get("/Teams/GetAllTeams.ashx", {
          params: { searchText: null, type: 2 },
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.teamOptions = resp.data;
            var defaultTeamValue = this.user.DefaultTeamId;
            if (this.teamOptions.length > 0 && defaultTeamValue) {
              var team = this.teamOptions.find((m) => m.Id == defaultTeamValue);
              if (team) {
                this.teamValue = team.Id;
              }
            }
            if (this.teamValue) {
              this.handleGetData();
            }
          }
        });
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      this.selMem = [];
    },
    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
};
